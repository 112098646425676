@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Rubik:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
    font-family: "Optima";
    src: url("../fonts/optima.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Freestyle";
    src: url("../fonts/freescpt.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
}
body {
  @apply font-normal relative overflow-x-hidden;
  font-family: Rubik, sans-serif;
}

section {
  @apply py-20 relative;
}

a {
  @apply transition-all duration-150 decoration-0;
}

p {
  @apply text-[14.4px] leading-6;
}

hr {
  @apply border-t border-gray-200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium leading-[1.2];
  font-family: Poppins, sans-serif;
}

.h1,
h1 {
  @apply text-[36px];
}

.h2,
h2 {
  @apply text-[30px];
}

.h3,
h3 {
  @apply text-[24px];
}

.h4,
h4 {
  @apply text-[18px];
}

.h5,
h5 {
  @apply text-[14px];
}

.h6,
h6 {
  @apply text-[12px];
}

::selection {
  @apply bg-black text-white;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* ------- Demo 2 wave animation --------- */
.wave {
  @apply absolute left-0 w-[200%] h-full;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.wave-one {
  background-size: 50% 100px;
}

.wave-anim .wave-one {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-two {
  background-size: 50% 120px;
}

.wave-anim .wave-two {
  animation: move_wave 10s linear infinite;
}

.wave-three {
  background-size: 50% 100px;
}

.wave-anim .wave-three {
  animation: move_wave 15s linear infinite;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.header_logo_text {
  @apply xl:max-w-4xl;
}

.svg-pattern {
  @apply w-full relative;
}

.play-btn {
  border: 2px solid;
  border-radius: 50%;
  border-color: #ffffff;
  color: #ffffff;
  width: 60px;
  height: 60px;
  display: block;
  font-size: 30px;
  margin-right: auto;
  margin-left: auto;
  line-height: 60px;
  margin-top: 10px;
  text-align: center;
}

.play-shadow {
  border-radius: 50%;
  border: 1px solid transparent;
  width: 82px;
  height: 82px;
  margin: 20px auto;
}

.aanmeldbutton-home {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 78/var(--tw-bg-opacity));
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.card {
  @apply shadow rounded border border-gray-100 bg-white;
}

.card-body {
  @apply py-10 px-11;
}

.btn {
  @apply inline-block px-5 py-2.5 rounded text-sm cursor-pointer select-none outline-none transition-all duration-500 focus:outline-none focus:ring-0 focus:ring-offset-0 hover:-translate-y-1.5;
}

.dropdown {
  @apply relative;
}

.dropdown-menu {
  @apply static lg:absolute lg:bg-white bg-transparent lg:shadow-lg shadow-none rounded z-20 w-40 lg:py-2 py-0 hidden;
  animation-name: DropDownSlide;
  animation-duration: 0.4s;
  animation-fill-mode: both;
}

@keyframes DropDownSlide {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
}
.dropdown-menu.show {
  @apply block;
}

.dropdown-item {
  @apply block px-4 py-3 text-sm lg:text-gray-500 text-gray-400 capitalize transition-colors duration-200 transform lg:hover:bg-gray-100 hover:bg-transparent;
}

@media (min-width: 992px) {
  .dropdown:hover > .dropdown-menu {
    @apply block;
  }
}
@media (min-width: 640px) {
  .schedule {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .schedule {
    width: 100%;
    -moz-columns: 2;
    columns: 2;
  }
}
@media (min-width: 1280px) {
  .schedule {
    width: 100%;
  }
}
.schedule {
  margin-left: auto;
  margin-right: auto;
}

.schedule > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.schedule {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

@media (min-width: 640px) {
  .schedule {
    padding-left: 0;
    padding-right: 0;
  }
}
.schedule li {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: rgb(237 223 195);
  --tw-gradient-to: rgb(245 245 244 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #ffffff;
  display: flex;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

@media (min-width: 1024px) {
  .schedule li {
    margin-left: -0.75rem;
  }
}
.schedule li {
  -moz-column-break-inside: avoid;
  break-inside: avoid-column;
}

.schedule time {
  flex: none;
  text-align: right;
  font-size: 1.125rem;
  font-weight: 600;
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  line-height: 2rem;
  letter-spacing: -0.025em;
  width: 5rem;
  padding-right: 0.75rem;
}

@media (min-width: 640px) {
  .schedule time {
    width: 8rem;
  }
}
@media (min-width: 768px) {
  .schedule time {
    width: 10rem;
  }
}
.talk {
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209/var(--tw-border-opacity));
  width: 100%;
  padding-left: 0.75rem;
}

.talk-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 640px) {
  .talk-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.talk-title span {
  margin-bottom: 0.25rem;
  display: block;
  line-height: 1;
}

.talk-speaker {
  font-weight: 500;
  line-height: 1.25;
  --tw-text-opacity: 1;
  color: rgb(87 83 78/var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .talk-speaker {
    font-size: 1.125rem;
  }
}
.talk-speaker a {
  text-underline-offset: 2px;
}

.talk-speaker a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.talk code {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgb(120 113 108/var(--tw-text-opacity));
}

.talk-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68/var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.talk-tag-lightning {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
  background-color: rgb(239 68 68/var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11/var(--tw-bg-opacity));
}

code, kbd, samp, pre {
  font-size: 1em;
}

.segment {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 640px) {
  .segment {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .segment {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .segment {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .segment {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .segment {
    max-width: 1536px;
  }
}
.segment {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

@media (min-width: 1024px) {
  .segment {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
#schedule_tabs > li > a.active > div, #tabs > li > a.active > div {
  --tw-text-opacity: 1;
  color: rgb(31 41 55/var(--tw-text-opacity));
}

#schedule_tabs2 a, #speaker_tabs a, #speaker_tabs div time {
  --tw-text-opacity: 0.7;
  color: rgb(31 41 55/var(--tw-text-opacity));
}

#schedule_tabs2 a.active, #speaker_tabs a.active, #speaker_tabs div.active time {
  --tw-text-opacity: 1;
  color: rgb(31 41 55/var(--tw-text-opacity));
}

.rounded-4xl {
  border-radius: 2rem;
}

.speakers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1024px) {
  .speakers {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .speakers {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media (min-width: 1536px) {
  .speakers {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
.speakers {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1280px) {
  .speakers li {
    width: 20%;
  }
}
.speakers li div {
  padding: 0.75rem;
}

@media (min-width: 1280px) {
  .speakers li div {
    padding-left: 0;
    padding-right: 0;
  }
}
.speaker-picture {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #1e3a8a var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  display: block;
  overflow: hidden;
  border-radius: 2rem;
  height: 6rem;
  width: 6rem;
}

@media (min-width: 640px) {
  .speaker-picture {
    height: 8rem;
    width: 8rem;
  }
}
@media (min-width: 1280px) {
  .speaker-picture {
    height: 10rem;
    width: 10rem;
  }
}
.speaker-picture {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.25rem;
}

.speaker-picture img {
  -o-object-fit: cover;
  object-fit: cover;
  mix-blend-mode: hard-light;
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.speaker-name {
  height: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.025em;
}

@media (min-width: 640px) {
  .speaker-name {
    font-size: 1.5rem;
  }
}
.speaker-name a {
  text-underline-offset: 2px;
}

.speaker-name a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

@media (min-width: 640px) {
  .speaker-name span {
    display: none;
  }
}
@media (min-width: 1280px) {
  .speaker-name span {
    display: inline;
  }
}
.speaker-title {
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(120 113 108/var(--tw-text-opacity));
}

@media (min-width: 1280px) {
  .speaker-title {
    margin-top: 0.25rem;
  }
}
#speaker_tabs div svg {
  stroke: #94a3b8;
  fill: transparent;
}

#speaker_tabs div.active svg {
  stroke: #142f4e;
  fill: #142f4e;
}

.partners {
  flex-wrap: wrap;
}

@media (min-width: 640px) {
  .partners {
    display: flex;
  }
}
.partners {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 640px) {
  .partners {
    padding-left: 0;
    padding-right: 0;
  }
}
.partners li {
  padding: 0.25rem 0.375rem;
}

.partner {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  height: 8rem;
  width: 100%;
}

@media (min-width: 640px) {
  .partner {
    height: auto;
  }
}
.partner img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.el-checkbox {
  color: currentColor !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.el-select {
  width: 100%;
}

.el-select-dropdown__item.selected {
  color: #052e4e !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #052e4e !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #052e4e !important;
  border-color: #052e4e !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #555 !important;
}

.el-radio {
  display: block !important;
}

.optie_volgeboekt {
  color: red;
}

.el-checkbox__input, .el-radio__input {
  vertical-align: top !important;
}

.el-checkbox__label, .el-radio__label {
  white-space: normal;
}

.el-radio__label {
  display: inline-block;
}

.el-input-number--mini, .el-input-number {
  width: 90px !important;
}

.el-checkbox__label, .el-radio__label {
  font-size: 16px !important;
  font-family: "Poppins", "Lato", sans-serif;
}

.toggleta {
  padding-left: 20px !important;
}

.toggle .togglet, .toggle .toggleta {
  font-weight: normal;
  line-height: 1.5;
  color: #555;
  font-family: "Poppins", "Lato", sans-serif;
}

.toggleta > div {
  margin-left: 20px;
}

@media (max-width: 778px) {
  .el-checkbox__label {
    display: block !important;
    padding-left: 0px !important;
  }
}
.mdi-close-box {
  cursor: pointer;
}

.selected-issuer-div p {
  color: #B51F1F;
}

.navbar-custom .navbar-nav .active .nav-item {
  @apply text-white;
}

.navbar-custom.is-sticky {
  @apply bg-bronze lg:py-3 shadow-sm shadow-black/30 dark:bg-zinc-800;
}

.navbar-custom {
  @apply lg:py-4 py-3 bg-gray-800 dark:bg-zinc-800 dark:xl:bg-transparent xl:bg-transparent border-gray-200 fixed top-0 left-0 right-0 z-50 transition-all ease-in-out duration-500;
}
.navbar-custom .navbar-nav {
  @apply flex flex-col mt-4 lg:flex-row lg:space-x-4 lg:mt-0 lg:text-[15px];
}
.navbar-custom .navbar-nav .nav-item {
  @apply text-gray-300/80 block p-3 border-gray-100 hover:text-white lg:hover:bg-transparent lg:border-0  xl:px-2;
}